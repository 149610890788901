<template>
  <v-container>
    <v-row justify="center" class="text-center pt-2 pb-4">
      <v-col class="mb-0" cols="12">
        <v-card elevation="8" class="pt-2 px-4 pb-8 rounded-xl">
          <v-card-title>
            {{ $t('headers.addressLookup') }} - {{ country }}
          </v-card-title>
          <v-row>
            <v-col class="mb-0" cols="12" sm="12" md="12">
              <v-autocomplete
                id="postalCode"
                clearable
                rounded
                outlined
                v-model="postalcode"
                :items="postalCodes"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                :rules="[postalCodeRules]"
                :label="$t('labels.postalCode')"
                :placeholder="$t('labels.startTyping')"
                @blur="onBlurPostal()"
                :hint="hint"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="line1"
                clearable
                rounded
                outlined
                :loading="isLoadingAddress"
                :search-input.sync="searchLine1"
                v-model="line1"
                :items="addressesLine1"
                hide-no-data
                hide-details
                :label="$t('labels.line1AT')"
                :placeholder="$t('labels.startTyping')"
                @input="moveToHouseNo()"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                id="line2"
                clearable
                rounded
                outlined
                v-model="line2"
                :search-input.sync="searchLine2"
                :items="addressesLine2"
                hide-no-data
                hide-details
                :label="$t('labels.line2AT')"
                :placeholder="$t('labels.startTyping')"
                @blur="onBlurLine2()"
              ></v-autocomplete>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-text-field
                v-model="line3"
                :label="$t('labels.line3AT')"
                outlined
                rounded
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="mb-0" cols="12" sm="12" md="6">
              <v-autocomplete
                clearable
                rounded
                outlined
                v-model="townCity"
                :items="addressesTownCity"
                hide-no-data
                hide-details
                :label="$t('labels.townCity')"
                :placeholder="$t('labels.startTyping')"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="mb-0" cols="12" sm="12" md="6">
        <p>Address: {{
            this.addressesResult.find((x) => x.postalCode === this.postalcode
              && x.line1 === this.line1
              && x.line2 === this.line2
              && x.townCity === this.townCity) || 'Not Valid'
          }}</p>
        <v-btn :disabled="!isValid" @click="webshopsLookup()">{{ isValid }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AddressLookupAT',
  data: () => ({
    isLoadingAddress: false,
    isLoading: false,
    showNav: false,
    orderType: '',
    fullAddress: null,
    postalcode: '',
    line1: '',
    line2: '',
    line3: '',
    townCity: '',
    locality: '',
    comment: '',
    err: '',
    warn: '',
    success: '',
    successPostal: '',
    deliveryCharges: null,
    distanceInMiles: 0,
    total: 0,
    itemsTotal: 0,
    validPostal: null,
    selectAddress: null,
    selectAddressAT: null,
    calcTimeout: null,
    latitude: null,
    longitude: null,
    resourceId: null,
    postalCodes: [],
    search: null,
    searchLine1: null,
    searchLine2: null,
    searchTownCity: null,
    searchAddress: null,
    hint: '',
    showAddressFields: false,
    addressesResult: [],
  }),
  computed: {
    ...mapGetters({
      lat: 'lat',
      lng: 'lng',
      dark: 'dark',
      country: 'country',
    }),
    postalCodeRules() {
      if (this.country && this.postalcode) {
        return this.testPostalCodeRules(this.country, this.postalcode);
      }
      return this.$t('errors.invalidZip');
    },
    addressesLine1() {
      return this.filterUniqueFields(this.addressesResult, 'line1');
    },
    addressesLine2() {
      return this.filterUniqueFields(this.addressesResult, 'line2');
    },
    addressesTownCity() {
      if (this.filterUniqueFields(this.addressesResult, 'townCity').length === 1) {
        // eslint-disable-next-line prefer-destructuring,vue/no-side-effects-in-computed-properties
        this.townCity = this.filterUniqueFields(this.addressesResult, 'townCity')[0];
      }
      return this.filterUniqueFields(this.addressesResult, 'townCity');
    },
    isValid() {
      const valid = this.addressesResult.find((x) => x.postalCode === this.postalcode
        && x.line1 === this.line1
        && x.line2 === this.line2
        && x.townCity === this.townCity);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (valid) this.fullAddress = valid;
      return !!valid;
    },
  },
  watch: {
    search: {
      handler(val) {
        if (val) {
          this.search = this.search.trim();
        }
        this.searchLine1 = '';
        this.addressesResult = [];
        this.getPostalCodes(val);
      },
      deep: true,
    },
    searchLine1: {
      handler() {
        if (this.testPostalCodeRules(this.country, this.postalcode) === true
          && this.searchLine1 && this.searchLine1.length > 2) {
          this.addressLookup(this.postalcode);
        }
      },
      deep: true,
    },
    postalcode() {
      this.postalcode = this.postalcode ? this.postalcode.trim() : '';
      if (this.testPostalCodeRules(this.country, this.postalcode) === true) {
        document.getElementById('postalCode')
          .blur();
        document.getElementById('line1')
          .click();
        document.getElementById('line1')
          .focus();
        this.line1 = null;
        this.searchLine1 = null;
        this.line2 = null;
        this.searchLine2 = null;
        this.townCity = null;
        this.line3 = null;
        this.addressLookup();
      }
    },
    line1() {
      if (!this.line1) {
        this.line2 = null;
        this.searchLine2 = null;
        this.townCity = null;
        this.line3 = null;
      }
    },
    line2() {
      if (!this.line2) {
        this.line3 = null;
      }
    },
  },
  methods: {
    moveToHouseNo() {
      document.getElementById('line1')
        .blur();
      document.getElementById('line2')
        .click();
      document.getElementById('line2')
        .focus();
    },
    getPostalCodes(code) {
      if (!code) return;
      code.trim()
        .toUpperCase();
      this.hint = '';
      if (this.country === 'UK' && (code.length < 4 || code.length > 16)) return;
      if (this.country === 'AT' && code.length !== 4) return;
      this.isLoading = true;
      this.$http.get(`address/postalcodes/country/${this.country}?startswith=${code.trim()
        .toUpperCase()}`)
        .then((res) => {
          if (res.data && res.status === 200) {
            if (res.data.postalCodes.length === 0) {
              this.hint = this.$t('errors.noPostalFound');
              this.isLoading = false;
            } else {
              if ((res.data.postalCodes.filter((x) => x === this.postalcode)).length === 0) {
                this.postalCodes = res.data.postalCodes;
                this.line1 = '';
                this.searchLine1 = '';
              }
              if (res.data.postalCodes.length === 1) {
                this.postalcode = this.postalCodes[0];
              }
              this.isLoading = false;
            }
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoading = false;
        });
    },
    addressLookup(value) {
      if (!value) return;
      if (this.country === 'UK' && (value.length < 5 || value.length > 16)) return;
      if (this.country === 'AT' && value.length !== 4) return;
      if ((!value || !this.searchLine1 || (this.searchLine1 && this.searchLine1.length < 3)) && this.country === 'AT') return;
      this.isLoadingAddress = true;
      this.addressesResult = [];
      const payload = this.country === 'AT'
        ? `?postalCode=${value.toUpperCase()}&line1=${this.searchLine1}`
        : `?postalCode=${value.toUpperCase()}`;
      this.$http.get(`address/addresses/${this.country}${payload}`)
        .then((res) => {
          if (res.data && res.status === 200 && res.data.addresses) {
            this.hint = this.$t('success.postalSet');
            this.addressesResult = res.data.addresses || [];
            this.latitude = res.data.addresses[0].latitude;
            this.longitude = res.data.addresses[0].longitude;
            this.isLoadingAddress = false;
          } else {
            this.hint = res.data.statusText;
            this.isLoadingAddress = false;
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoadingAddress = false;
        });
    },
    webshopsLookup() {
      const address = this.addressesResult.find((x) => x.postalCode === this.postalcode
        && x.line1 === this.line1
        && x.line2 === this.line2
        && x.townCity === this.townCity);
      const payload = `?lat=${address.latitude}&lng=${address.longitude}&cuisine=Italian&maxDistance=30&distanceUnit=km`;
      this.$http.get(`searchwebshops/${payload}`)
        .then((res) => {
          if (res.data && res.status === 200) {
            console.log(res.data);
          } else {
            console.log('Error');
          }
        })
        .catch((err) => {
          this.err = err;
          this.isLoadingAddress = false;
        });
    },
    testPostalCodeRules(countryToCheck, zip) {
      if (!zip) return this.$t('errors.zipRequired');
      if (!countryToCheck) return this.$t('errors.countryRequired');
      if (countryToCheck === 'UK') {
        return zip.length > 5 && zip.length < 16;
      }
      if (countryToCheck === 'AT') {
        return zip.length === 4;
      }
      return this.$t('errors.countryUnsupported');
    },
    onBlurPostal() {
      if (this.postalCodes.includes(this.search)) {
        this.postalcode = this.search;
        this.addressLookup(this.postalcode);
      }
    },
    onBlurLine2() {
      if (this.searchLine2 && this.addressesLine2.includes(this.searchLine2.toUpperCase())) {
        this.line2 = this.searchLine2.toUpperCase();
      }
    },
    filterUniqueFields(addresses, property) {
      let filteredFieldList = addresses.map((address) => address[property]);
      // eslint-disable-next-line max-len
      filteredFieldList = filteredFieldList.filter((item, index) => filteredFieldList.indexOf(item) === index)
        .filter((x) => x !== undefined)
        .sort();
      return filteredFieldList;
    },
  },
  created() {
    console.log(this.$t('app_name'));
    console.log(this.country.toString());

    // const searchRestaurants = () => {
    // eslint-disable-next-line max-len
    // this.$http.get(`searchwebshops?lat=${position.coords.latitude}&lng=${position.coords.longitude}&maxDistance=5&distanceUnit=km`)
    //   .then((response) => {
    //     console.log(response.data);
    //   });
    // };

    // const showPosition = (position) => {
    //   console.log(position.coords);
    //   localStorage.GeolocationPosition = JSON.stringify({
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    //   searchRestaurants(position);
    // };

    if (navigator.geolocation) {
      // navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  },
};
</script>
